import { useScroll } from '..'

interface UseScrolledOptions {
  element?: Ref<HTMLElement | null>
  offsetY?: number
}

export function useScrolled({ element, offsetY = 1 }: UseScrolledOptions = {}) {
  const scrollRef = ref<Document | HTMLElement | null>(null)
  const { y } = useScroll(scrollRef)
  const isScrolled = computed(() => y.value > offsetY)

  onMounted(async () => {
    scrollRef.value = element?.value || document
  })

  return {
    isScrolled,
  }
}
